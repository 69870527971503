import * as React from "react";
import { HeadFC, Link, PageProps, graphql } from "gatsby";
import { Link as MuiLink, Typography, TypographyProps, styled } from '@mui/material';
import Layout from "../components/layout";
import Seo from "../components/seo";
import * as stylesHome from "../styles/home.module.css";
import { StaticImage } from 'gatsby-plugin-image';
import { orange } from "@mui/material/colors";

type DataProps = {
  site: {
    siteMetadata: {
      storeUrl: string
    }
  }
}

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        storeUrl
      }
    }
  }
`
const TypographyCentered = styled(Typography)<TypographyProps>(({theme}) => ({
  maxWidth: '100%',
  textAlign: 'center',
}))

const IndexPage: React.FC<PageProps<DataProps>> = ({data, location}) => 
{
  return (
  <Layout>
    <div>
      <StaticImage
            className={stylesHome.heroBanner}
            src="../images/snow-banner1.png"
            alt="The Story Banner"
            loading="eager"

            quality={95}
            formats={["auto", "webp", "avif"]}
            style={{ marginRight: '0.3rem' }}
          />
    </div>
    <TypographyCentered variant="h1">Hey There, Adventurers and other Doers!</TypographyCentered>
    <Typography variant="body1">
    Picture this: Mikko, our founder, chilling near the Polar Circle in Finland - a place so cool (literally) it makes your average freezer seem like a sauna. This guy's got a knack for turning "Why didn't I think of that?" into "I need that yesterday!" ideas. That's how Ultra Handy was born – out of a need for stuff that actually, well, works.
    </Typography>
    <TypographyCentered variant="h2">
    Chapter 1: "Eureka!" said the Finn
    </TypographyCentered>
    <Typography>
    Mikko, battling the elements (and probably a moose or two), thought, "Why can't our gear be as tough and smart as us Finns?" And thus, Ultra Handy was born - because who has time for accessories that are all show and no go?
    </Typography>
    <TypographyCentered variant="h2">
      Chapter 2: Crossing the Atlantic
    </TypographyCentered>
    <Typography>
    Now, we could've stayed in Finland, but we wanted to share our genius with the world. Enter: The Good Ol' USA. Why? Because when you mix Finnish ingenuity with American hustle, you get something like a superhero team for gear. And also because the company that loved our idea is American – transparency, remember?
    </Typography>
    <TypographyCentered variant="h2">
    Chapter 3: Our Mantra: Keep It Fun, Keep It Real, Listen customers
    </TypographyCentered>
    <Typography>
    Ultra Handy isn't just a brand; it's your wisecracking buddy who also happens to be incredibly reliable. 
    Our philosophy? If it's not fun, functional, and a bit cheeky, it's not Ultra Handy material. 
    Of course it has been clear from beginning that we want to  
    listen our be loved customers. We are all ears if you guys (and girls) have feedback (positive, negative, just want to share story about your annoying neighbour) or idea for the product you would need, hmm?
    </Typography>
    <TypographyCentered variant="h2">
    Chapter 4: Gear Today, Health Tomorrow
    </TypographyCentered>
    <Typography>
    Our products? They're like the Swiss Army Knife went on a date with a Finnish sauna and had a love child. 
    Whether you're scaling a mountain or just trying not to scale your pile of paperwork, we've got your back.
    When our current lineup is like if your favorite comedian was also your trusty sidekick. 
    And guess what? We're brainstorming some health supplements because we care about what's on the inside too - and we're not just talking about pockets.
    </Typography>
    <TypographyCentered variant="h2">
    Chapter 5: The Ultra Handy Clan
    </TypographyCentered>
    <Typography>
    Joining Ultra Handy means getting more than just gear. It's like getting a new group of friends who care about your adventures and your health. We're the folks who bring you tools that don't fail and might soon bring you supplements that make you hail!
    </Typography>
    <TypographyCentered variant="h2">
    The Big Picture
    </TypographyCentered>
    <Typography>
    As we march forward, our goal is to be there for providing practical solutions for your needs - from conquering mountains to conquering your wellness goals. 
    Ultra Handy isn't just a brand; it's a lifestyle. 
    So, gear up, chuckle on, and stay tuned for our health-boosting goodies coming your way!
    As Ultra Handy continues to grow, it remains true to its roots - a brand born from the practical mind of a Finn, nurtured in the entrepreneurial spirit of America, and dedicated to serving the global community of professionals and adventurers. 
    In every product, the spirit of Mikko and the essence of the Finnish wilderness live on, guiding the brand into the future.
    </Typography>

      <TypographyCentered sx={{ textAlign: "center" }}>
        <b>You can check out our product right away from our <MuiLink href={data.site.siteMetadata.storeUrl} underline="hover" rel="noopener" target="_blank">Amazon Store</MuiLink></b>
      </TypographyCentered>
  </Layout>
)}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export const Head: HeadFC<DataProps> = () => <Seo title="Home of Ultra Handy" />

export default IndexPage
